import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DesktopMenuButtonComponent } from '@commons/main-menu/desktop-main-menu/desktop-secondary-menu-button/desktop-secondary-menu-button.component';
import { MenuEntry } from '@commons/main-menu/service/entries';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';
import { trackEvent } from '@wizbii/utils/tracking';

@Component({
  selector: 'app-desktop-main-menu',
  template: `<!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <nav
      class="tw-flex tw-items-center tw-justify-between tw-rounded-2xl tw-border tw-bg-white tw-px-8 tw-py-2 tw-shadow-default"
      role="navigation"
    >
      <div class="tw-flex tw-items-center tw-gap-x-8">
        @if (productLogo(); as productLogo) {
          <a [routerLink]="['/']" tabindex="-1">
            <span class="visually-hidden">Accueil</span>
            <img class="tw-h-16 tw-w-32" [ngSrc]="productLogo" height="64" width="128" alt="" placeholder imageLoader />
          </a>
        }

        @if (entries().length > 0) {
          <ul class="tw-flex tw-gap-x-6" role="menu">
            @for (entry of entries(); track $index) {
              <li class="tw-relative">
                @if (entry.isInternalLink) {
                  <a
                    class="tw-no-underline before:tw-invisible before:tw-font-semibold before:tw-content-[attr(title)]"
                    [routerLink]="entry.route"
                    [title]="entry.label"
                    (click)="trackEntry(entry.label)"
                    #internalLinkActive="routerLinkActive"
                    role="menuitem"
                    routerLinkActive
                  >
                    <span
                      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-max tw-text-base tw-font-medium tw-text-black hover:tw-font-semibold hover:tw-text-primary"
                      [ngClass]="{
                        'tw-font-semibold tw-text-primary': internalLinkActive.isActive,
                      }"
                    >
                      {{ entry.label }}
                    </span>
                  </a>
                } @else {
                  <a
                    class="tw-no-underline before:tw-invisible before:tw-font-semibold before:tw-content-[attr(title)]"
                    [href]="entry.route"
                    [target]="entry.target"
                    [title]="entry.label"
                    (click)="trackEntry(entry.label)"
                    #internalLinkActive="routerLinkActive"
                    role="menuitem"
                    routerLinkActive
                  >
                    <span
                      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-font-medium tw-text-black hover:tw-font-semibold hover:tw-text-primary"
                      [ngClass]="{
                        'tw-font-semibold tw-text-primary': internalLinkActive.isActive,
                      }"
                    >
                      {{ entry.label }}
                    </span>
                  </a>
                }
              </li>
            }
          </ul>
        }
      </div>

      @if (secondaryEntries(); as secondaryEntries) {
        @if (secondaryEntries.length > 0) {
          <app-desktop-menu-button [entries]="secondaryEntries" />
        }
      }
    </nav> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgOptimizedImage, DesktopMenuButtonComponent, NgClass],
})
export class DesktopMainMenuComponent {
  readonly entries = input.required<MenuEntry[]>();
  readonly secondaryEntries = input.required<MenuEntry[]>();

  readonly #partner = inject(Store).selectSignal(selectPartner);
  readonly productLogo = computed(() => {
    const partner = this.#partner();
    if (!partner) throw Error('Missing partner');

    return partner.partnerConfiguration.assets.logos.productLogo.url;
  });

  readonly trackEntry = (entryLabel: string) => trackEvent('menu-principal-desktop', 'click', entryLabel);
}
