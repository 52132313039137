<!-- eslint-disable @angular-eslint/template/no-call-expression -->
@if (vm$ | async; as vm) {
  <nav role="navigation">
    <ul class="breadcrumbs">
      <ng-template #homeIcon>
        <mat-icon
          class="breadcrumbs__link__home__icon"
          [class.breadcrumbs__link--active]="vm.isHomeColorized"
          [svgIcon]="HomeIcon"
        />
      </ng-template>

      <li class="breadcrumbs__item">
        @if (vm.routerLink) {
          <a
            class="breadcrumbs__link breadcrumbs__link__home"
            [routerLink]="vm.routerLink"
            [routerLinkActiveOptions]="{ exact: true }"
            [attr.aria-label]="'Aller à la page d\'accueil de ' + vm.productName"
            (click)="trackClickEvent('dashboard')"
            routerLinkActive="breadcrumbs__link--active"
          >
            <ng-container *ngTemplateOutlet="homeIcon" />
          </a>
        }
        @if (vm.href) {
          <a
            class="breadcrumbs__link breadcrumbs__link__home"
            [href]="vm.href"
            [attr.aria-label]="'Aller à la page d\'accueil de ' + vm.productName"
            (click)="trackClickEvent('dashboard')"
          >
            <ng-container *ngTemplateOutlet="homeIcon" />
          </a>
        }
        @if (vm.breadcrumbs.length) {
          <mat-icon class="breadcrumbs__next-icon" [svgIcon]="ChevronRight" aria-hidden="true" />
        }
      </li>
      @if (vm.breadcrumbs.length > 1 && showMoreButton()) {
        <li class="breadcrumbs__item">
          <button
            class="breadcrumbs__ellipsis"
            [matMenuTriggerFor]="menu"
            type="button"
            aria-label="Voir tous les liens"
            panelClass="breadcrumbs-menu"
          >
            <mat-icon class="breadcrumbs__ellipsis__icon" [svgIcon]="More" />
          </button>
          <mat-icon class="breadcrumbs__next-icon" [svgIcon]="ChevronRight" aria-hidden="true" />
        </li>
      }
      @for (breadcrumb of vm.breadcrumbs; track breadcrumb.label; let index = $index; let isLast = $last) {
        <li class="breadcrumbs__item" [class.breadcrumbs__item--hide]="showMoreButton() && !isLast">
          @if (isLast) {
            <p
              class="breadcrumbs__link"
              [class.breadcrumbs__link--main]="index === 0"
              [class.breadcrumbs__link--last]="isLast"
            >
              <span class="breadcrumbs__link__label" #lastBreadcrumb>{{ breadcrumb.label }}</span>
            </p>
          } @else {
            <a
              class="breadcrumbs__link"
              [class.breadcrumbs__link--main]="index === 0"
              [class.breadcrumbs__link--last]="isLast"
              [routerLink]="breadcrumb.route"
              [relativeTo]="null"
              (click)="trackClickEvent('navigation', breadcrumb.label)"
              ><span class="breadcrumbs__link__label">{{ breadcrumb.label }}</span>
            </a>
            <mat-icon class="breadcrumbs__next-icon" [svgIcon]="ChevronRight" aria-hidden="true" />
          }
        </li>
      }
    </ul>

    <mat-menu class="mobile-more-breadcrumbs" #menu="matMenu">
      <mat-icon class="mobile-more-breadcrumbs__close-icon" [svgIcon]="CloseIcon" aria-hidden="true" />
      <ul>
        @for (breadcrumb of vm.mobileMoreBreadcrumb; track breadcrumb.label) {
          <li class="mobile-more-breadcrumbs__item" mat-menu-item>
            <a
              class="mobile-more-breadcrumbs__link"
              [routerLink]="breadcrumb.route"
              [relativeTo]="null"
              (click)="trackClickEvent('navigation', breadcrumb.label)"
              >{{ breadcrumb.label }}
            </a>
          </li>
        }
      </ul>
    </mat-menu>
  </nav>
}
