import { Component, inject, input, model } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LogoComponent, TEXT_ONLY } from '@commons/logo/logo.component';
import { MenuEntry } from '@commons/main-menu/service/entries';
import { LoyaltyIcons } from '@core/utils/icons-utils';
import { CoPlatformMainMenuCardComponent } from '@domains/burger-menu/co-platform-main-menu-card/co-platform-main-menu-card.component';
import { Store } from '@ngrx/store';
import { fadeEnter, fadeLeave } from '@shared/animations/fade-animations';
import { hasCoPlatform } from '@stores/partner/partner.selectors';
import { jwtDelete } from '@wizbii-utils/angular/stores';
import { trackEvent } from '@wizbii/utils/tracking';

@Component({
  selector: 'app-mobile-secondary-menu',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, MatIconModule, LogoComponent, CoPlatformMainMenuCardComponent],
  animations: [fadeEnter('200ms'), fadeLeave('200ms')],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <nav
      class="tw-no-scrollbar tw-modal tw-fixed tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-white"
      [@fadeEnterAnimation]
      [@fadeLeaveAnimation]
      role="navigation"
      aria-label="Menu de navigation"
    >
      <div class="tw-h-full tw-w-full tw-p-6">
        <div class="tw-flex tw-justify-between">
          <app-logo
            [desktopLogoBehavior]="LOGO_BEHAVIOR_TEXT_ONLY"
            [mobileLogoBehavior]="LOGO_BEHAVIOR_TEXT_ONLY"
            [defaultLogoMobileWidth]="215"
            [defaultLogoHeight]="64"
          />
          <button (click)="trackAndCloseMenu()" aria-label="Fermer le menu" type="button">
            <mat-icon [svgIcon]="closeSquareIcon" />
          </button>
        </div>

        <ul class="tw-mx-2 tw-my-6 tw-grid tw-h-auto tw-w-auto tw-gap-4" role="menu">
          @for (entry of secondaryEntries(); track $index) {
            <li role="none">
              @if (entry.isInternalLink) {
                <a
                  class="tw-text-lg tw-font-medium tw-text-black tw-no-underline"
                  [routerLink]="entry.route"
                  (click)="onMobileItemClick(entry.label)"
                  routerLinkActive="tw-text-primary"
                  role="menuitem"
                >
                  {{ entry.label }}
                </a>
              } @else {
                <a
                  class="tw-text-lg tw-font-medium tw-text-black tw-no-underline"
                  [href]="entry.route"
                  [target]="entry.target"
                  (click)="onMobileItemClick(entry.label)"
                  routerLinkActive="tw-text-primary"
                  role="menuitem"
                >
                  {{ entry.label }}
                </a>
              }
            </li>
          }
          <li role="none">
            <button
              class="tw-text-lg tw-font-medium tw-text-black tw-no-underline"
              (click)="logout()"
              role="menuitem"
              type="button"
            >
              Se déconnecter
            </button>
          </li>
        </ul>

        @if (hasCoPlatform()) {
          <app-co-platform-main-menu-card />
        }
      </div>
    </nav>
  `,
})
export class MobileSecondaryMenuComponent {
  readonly secondaryEntries = input.required<MenuEntry[]>();
  readonly isBurgerMenuOpen = model.required<boolean>();

  readonly #store = inject(Store);
  readonly hasCoPlatform = this.#store.selectSignal(hasCoPlatform);

  readonly #trackingCategory = 'menu-secondaire-mobile' as const;

  readonly closeSquareIcon = LoyaltyIcons.CloseSquare;
  readonly LOGO_BEHAVIOR_TEXT_ONLY = TEXT_ONLY;

  trackAndCloseMenu() {
    trackEvent(this.#trackingCategory, 'click', 'fermer menu secondaire');
    this.#closeMenu();
  }

  onMobileItemClick(entryLabel: string) {
    trackEvent(this.#trackingCategory, 'click', entryLabel);
    this.#closeMenu();
  }

  logout() {
    trackEvent(this.#trackingCategory, 'click', 'déconnecter');
    this.#store.dispatch(jwtDelete());
    this.#closeMenu();
  }

  #closeMenu() {
    this.isBurgerMenuOpen.set(false);
  }
}
